body{
	background:#000;
	p,span,h1,h2,h3,h4,h5,h6,ul,li,a,div{
			color:#fff !important;
		}
}

		

	

		.wcag_top{
			background:#fff;

			.btn_sprite--search{
			 background-position: -412px 0px;
			 &:hover,&:focus{
			 	opacity:1;
			 }
			}

			.search-submit{
				background:#000;
				&:hover,&:focus{
					opacity:1;
					text-decoration: underline;
					color:#fff;
				}
			}

			.input--search{
				border:2px solid #000;
				border-radius:0;
				color:#000;
				&::placeholder{
					color:#000;
				}
			}

		}

		.menu{

		}

		.page, .page_contact{
			background:#000;
			border-bottom:2px solid #fff;

			&_header{
				background:#000;
				border-bottom:2px solid #fff;
			}
		}

		.widget_top{
			background:none;
			border-bottom:2px solid #fff;
		}

		.news{
			background:none;
		
			&_content--list{
				border:0;
			}
			.heading{
				color:#fff;
			}

			&_text{

				&-title{
					color:#fff;
				}
				&-date{
					color:#fff;
				}
				&-text{
					color:#fff;
				}
			}

			&-link{
				&:hover .news-readMore,	&:focus .news-readMore{
				text-decoration:underline;
					background:none;
					color:#fff;
				}
			}

			&-readMore{
				color:#fff;
				border-color:#fff;
				
			}

		}
		
		.single_news{

		&-title{
			color:#fff;
		}

		&-date{
			color:#fff;
		}

		&-photo{
			color:#fff;
		}


	}
	.news_images .gallery_image-thumb{
		transition:none;
		&:hover,&:focus{
			transform:none;
		}
	}
		.recommended{
			background:#000;
			border-bottom:2px solid #fff;
			border-top:2px solid #fff;
			.heading{
				color:#fff;
			}
		}

		.contact_block{
			background:none;
		}

		.footer{
			background:none;
		}

		.gallery_image{
			&-thumb{
				transition:none;
				&:hover,&:focus{
				transform: none;
			}
			}
		}

		hr{
			border:0;
		}

		.search_text{
			color:#fff;
			&-title{
				color:#fff;
			}
		}

		.search-close{
			color:#000 !important;
		}
		
		.menu{
			background:#000;

			.dropdown-submenu{
				.dropdown-toggle{
					border-color:#000 !important;
				}
			}
		
		.navbar-nav .activeMenu.test{
			color:#fff;
			border-color:#000;
			text-decoration:underline;
		}
		.navbar-nav li a{
			color:#fff;
			border-color:#000;

			&:hover,&:focus{
				border-color:#000;
				background:none;
				text-decoration: underline;
				color:#fff;
			}
		}
			
			.nav .open > a, .nav .open > a:hover, .nav .open > a:focus{
				background:none;
				border-color:#000;
			}
			.navbar-nav li .dropdown-menu{
				border-color:#fff !important;
				background:#000; 
				border:4px solid #fff;

				&::before{
					border-color:#fff;
					background:#000;
				}

			}
			.navbar-nav li.activeMenu .dropdown-toggle{
				color:#fff;
				border-color:#000 !important;
				text-decoration: underline;
			}

			.navbar-nav li.activeMenu .dropdown-menu li .test{
				color:#fff;
				text-decoration:none;

			}

			.navbar-nav li.activeMenu .dropdown-menu li.activeMenu .test{
				color:#fff;
				text-decoration:underline;

			}
			

            .divider{

                  

                background-color:#000 !important;
                 border-bottom:1px solid #C8D0D2;
            }
            }

.navbar-nav li.activeMenu .test{
	color:#fff;
	border-color:#000;
	text-decoration:underline;
}

		.nav .open > a, .nav .open > a:hover, .nav .open > a:focus{

      border-color:#000;
            background:#000;
            color:#fff;
}


.pagination{
		border:2px solid #fff !important;
	}

	.pagination .active span{
		background:#000 !important;
		border-color:#000 !important;
	}
	.pagination > li > a, .pagination > li > span{
		border-color:#000 !important;
		color:#000 !important;
	}

	.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus{
		color:#fff !important;
	}





@media (max-width: $screen-md - 1px){
	.navbar-toggle {

	.icon-bar{
		border-color:#fff;

		&.cont{
		border-color:#000;
		}
	}

}		

	/*	
	 .menu_left .navbar-nav li a, .menu .navbar-nav li a:hover, .menu .navbar-nav li a:focus{
		border-color:#fff;
		color:#000;
	}

	.menu_left .navbar-nav li.activeMenu .dropdown-toggle{
		border-color:#fff;
		color:#000;
	}*/




	.menu_left{
		background:#fff;

		.navbar-nav{
			li{
				.test{
					color:#000 !important;
					&:focus,&:hover{
						border-color:#fff;
						color:#000 !important;
					}
					&.activeMenu{
						color:#000 !important;
						&:hover,&:focus{
							color:#000 !important;
						}

					}
				}

				a{
					border-color:#fff;
				}

				&.activeMenu{
					.test{
						border-color:#fff;
					}

					.dropdown-menu{
						li{
							.test{
								color:#000 !important;
							}

							&.activeMenu{
								.test{
									color:#000 !important;
								}
							}
						}
					}
				}

				.dropdown-menu{
					background:#fff;
					.divider{
						background-color:#fff !important;
					}

					li{
						a{
							color:#000 !important;
						}
					}
				}
			}
		}
	}
	.navbar-collapse.collapse .navbar-nav li a.dropdown-toggle{
		border-color:#000;
		color:#000 !important;
	}
.navbar-collapse.collapse .navbar-nav li a.dropdown-toggle{
	border-color:#fff;
	color:#000 !important;
}
	

}
		
	


